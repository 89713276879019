import { ReactNode, useState, useEffect, useRef, RefObject } from 'react';
import clx from 'classnames';
import style from './Carousel.module.css';

export interface CarouselProps {
  carouselNavRef?: RefObject<HTMLDivElement>;
  className?: string;
  content: string;
  currentSlide: number;
  media: ReactNode;
  title: string;
  totalSlides: number;
  onSelect: (newSlide: number) => void;
}

interface StoredSlide {
  media: ReactNode;
  title: string;
  content: string;
}

const MAX_IMAGE_SIZE = 510;

export const Carousel = ({
  carouselNavRef,
  className,
  content,
  currentSlide,
  media,
  title,
  totalSlides,
  onSelect,
}: CarouselProps) => {
  const [mediaVisible, setMediaVisible] = useState(true);
  const [storedSlide, setStoredSlide] = useState<StoredSlide>({
    media,
    title,
    content,
  });

  const mediaRef = useRef<HTMLDivElement>(null);
  const [mediaWidth, setMediaWidth] = useState<number>(0);

  const updateMediaWidth = () => {
    if (mediaRef.current) {
      const newWidth = mediaRef.current.clientWidth;
      setMediaWidth(newWidth);
    }
  };

  useEffect(() => {
    updateMediaWidth();

    window.addEventListener('resize', updateMediaWidth);

    return () => {
      window.removeEventListener('resize', updateMediaWidth);
    };
  }, []);

  useEffect(() => {
    setMediaVisible(false);
    const timeout = setTimeout(() => {
      setStoredSlide({
        media,
        title,
        content,
      });
      setMediaVisible(true);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [title, content, media]);

  return (
    <div className={clx(style.Carousel, className)}>
      <div className={style.CarouselBody}>
        <div
          ref={mediaRef}
          style={{ maxHeight: `${Math.min(mediaWidth, MAX_IMAGE_SIZE)}px` }}
          className={clx(style.Media, {
            [style.hidden]: !mediaVisible,
          })}
        >
          {storedSlide.media}
        </div>
        <div
          className={clx(style.Content, {
            [style.hidden]: !mediaVisible,
          })}
        >
          <h2 className={style.Title}>{storedSlide.title}</h2>
          <p className={style.Description}>{storedSlide.content}</p>
        </div>
      </div>
      <div className={style.CarouselNavPlaceholder}>
        <div className={clx(style.CarouselNav)} ref={carouselNavRef}>
          {new Array(totalSlides).fill(null).map((_, i) => (
            <button
              key={i}
              className={clx(style.NavBtn, {
                [style.NavBtnActive]: currentSlide === i,
              })}
              onClick={() => onSelect(i)}
            ></button>
          ))}
        </div>
      </div>
    </div>
  );
};
