import { ThemeContext } from 'common/context/Theme';
import { themeTypes } from 'common/context/Theme/ThemeContext';
import { useContext, useEffect, useState } from 'react';
import clx from 'classnames';

const FieldSet = ({ open, value, focused, selected, label, isLocked }: any) => {
  const { secondaryColor } = useContext<themeTypes>(ThemeContext);

  const [isFocused, setIsFocused] = useState(focused);

  useEffect(() => {
    setIsFocused(focused);
  }, [focused]);

  return (
    <>
      <style jsx>{`
        .select_legend {
          float: unset;
          overflow: hidden;
          display: block;
          width: auto;
          padding: 0;
          padding-right: 8px;
          height: 11px;
          font-size: 12.5px;
          visibility: hidden;
          -webkit-transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
          transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
          white-space: nowrap;
        }
        .field {
          text-align: left;
          position: absolute;
          bottom: 0;
          right: 0;
          top: -5px;
          left: 0;
          margin: 0;
          padding: 0 8px;
          z-index: 5;
          border-radius: inherit;
          border-style: solid;
          border-width: 1px;
          min-width: 0%;
          background: ${isLocked ? '#e8e8e8' : 'transparent'};
          border-radius: 4px;
          min-height: 100%;
          border: var(--mui-input-border-default);
        }
        .field:focus {
          outline: none;
          border: 0 none;
        }
        .legend {
          float: unset;
          overflow: hidden;
          display: block;
          width: auto;
          padding: 0;
          padding-right: 8px;
          height: 11px;
          font-size: 15px;
          visibility: hidden;
          max-width: 0.01px;
          -webkit-transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
          transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
          white-space: nowrap;
        }
      `}</style>
      <fieldset className="field">
        {label && (
          <legend
            className={clx(
              open || isFocused || value || selected
                ? 'select_legend'
                : 'legend',
            )}
          >
            <span>
              {' '}
              {typeof label === 'object' ? Object.values(label) : label}
              &nbsp;
            </span>
          </legend>
        )}
      </fieldset>
    </>
  );
};
export default FieldSet;
