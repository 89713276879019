import { MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { slides } from './gallery';
import { Carousel } from './Carousel';
import style from '../AuthLayout.module.css';
import { useArrowKeys } from 'common/hooks/useArrowKeys';

interface AuthLayoutGalleryProps {
  className: string;
}

const AUTOPLAY_BREAK = 10000;
const lastSlideIndex = slides.length - 1;

export const AuthLayoutGallery = ({ className }: AuthLayoutGalleryProps) => {
  const [slide, setSlide] = useState<number>(0);

  const carouselNavRef = useRef<HTMLDivElement>(null);

  /** number - slide index, true for next slide, false for prev slide */
  const changeSlide = useCallback(
    (newSlide: number | boolean = true) => {
      if (
        typeof newSlide === 'number' &&
        newSlide >= 0 &&
        newSlide <= lastSlideIndex
      ) {
        setSlide(newSlide);
        return;
      }
      setSlide((currentSlide: number) => {
        if (newSlide) {
          return currentSlide === lastSlideIndex ? 0 : currentSlide + 1;
        }
        return currentSlide === 0 ? lastSlideIndex : currentSlide - 1;
      });
    },
    [setSlide],
  );

  const handleClick : MouseEventHandler<HTMLElement> = useCallback(
    (event) => {
      const target = event.target as HTMLElement;
      if (carouselNavRef.current && !carouselNavRef.current.contains(target)) {
        changeSlide();
      }
    },
    [changeSlide],
  );

  useEffect(() => {
    const intervalId = setInterval(changeSlide, AUTOPLAY_BREAK);
    return () => clearInterval(intervalId);
  }, [changeSlide, slide]);

  useArrowKeys({
    onLeftArrowPress: () => changeSlide(false),
    onRightArrowPress: () => changeSlide(),
  });

  const activeSlide = slides[slide]

  return activeSlide ? (
    <div className={className} onClick={handleClick}>
      <Carousel
        className={style.Carousel}
        content={slides[slide].content}
        currentSlide={slide}
        media={
          // eslint-disable-next-line @next/next/no-img-element
          <img 
            className={style.CarouselImage}
            src={activeSlide.src}
            alt={activeSlide.alt}
            width={896}
            height={896}
          />
        }
        title={activeSlide.title}
        totalSlides={slides.length}
        onSelect={changeSlide}
        carouselNavRef={carouselNavRef}
      />
    </div>
  ) : null;
};
