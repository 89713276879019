import { AuthGallerySlide } from './types';

export const slides: AuthGallerySlide[] = [
  {
    title:
      'Every word you write, every idea you express, has the potential to inspire, educate, and entertain others.',
    content:
      'Remember that your unique voice holds tremendous power to touch lives, spark conversations, and create lasting connections. With each publication, you contribute to the rich tapestry of human knowledge and imagination. ',
    src: '/images/auth/Slide1.gif',
    alt: 'auth layout slide',
  },
  {
    title: 'Unleash Your Storytelling Potential!',
    content:
      'Step into a world where you hold the power to create captivating stories that touch hearts, ignite minds, and inspire generations. With our cutting-edge storytelling tools, your creativity knows no bounds!',

    src: '/images/auth/Slide2.gif',
    alt: 'auth layout slide',
  },
  {
    title:
      'Both products and affiliates under an intuitive and user-friendly interface',
    content:
      'Our e-commerce platform enables you to add, edit, and organize your products, including descriptions and images, showcasing your offerings in a visually appealing manner .',
    src: '/images/auth/Slide3.gif',
    alt: 'auth layout slide',
  },
  {
    title:
      'Unleash your creativity and add your personal touch using an AI-powered assistant that helps you craft curated content.',
    content:
      'Are you ready to revolutionize the way you curate content? Say goodbye to hours spent searching for the perfect articles, videos, and images. Our AI-driven technology scours the vast corners of the internet, curating the most relevant, high-quality content tailored to your specific needs. Harness the power of automation and let our platform handle the heavy lifting for you.',
    src: '/images/auth/Slide4.gif',
    alt: 'auth layout slide',
  },
  {
    title: 'Maximize your ad revenue with ease. ',
    content:
      'With our platform, you have the flexibility to experiment with various ad formats, sizes, and placements to find the perfect combination that ensures optimal user experience while driving exceptional revenue.',
    src: '/images/auth/Slide5.gif',
    alt: 'auth layout slide',
  },
];
