import { ReactNode } from 'react';
import style from './AuthLayout.module.css';
import { LogoFullIcon } from 'common/Layouts/components/Logo';
import { BsLogo } from 'common/Layouts/components/BsLogo';
import Head from 'next/head';

interface AuthLayoutProps {
  children: ReactNode;
}

export const AuthCenterLayout = ({ children }: AuthLayoutProps) => {
  return (
    <>
      <Head>
        <meta
          name="description"
          content="The innovative web content solution trusted by over 200 publications Our realtime CMS makes creating content fast and provides journalists with an evolving tool suite using data-driven AI "
        />
        <meta name="robots" content="noindex"></meta>
        <link rel="icon" href="/icons/favicon-16x16.png" sizes="16x16" />
        <link rel="icon" href="/icons/favicon-32x32.png" sizes="32x32" />
        <link rel="apple-touch-icon" href="/icons/apple-touch-icon.png" />
        <link
          rel="icon"
          type="image/png"
          sizes="256x256"
          href="/icons/android-chrome-256x256.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/icons/android-chrome-192x192.png"
        />
        <meta
          name="msapplication-TileImage"
          content="/icons/mstile-150x150.png"
        />
        <link
          rel="mask-icon"
          href="/icons/safari-pinned-tab.svg"
          color="#5bbad5"
        />
      </Head>
      <div className={style.AuthCenterLayout}>
        <div className={style.AuthLayoutContent}>
          <div className={style.Main}>
            <LogoFullIcon className={style.Logo} width={175} height={44} />
            {children}
          </div>
          <div className={style.Copyrights}>
            <BsLogo />
            <span>All rights reserved 2024. v2.0.0</span>
          </div>
        </div>
      </div>
    </>
  );
};
