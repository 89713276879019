import { FC, RefObject, ReactNode } from 'react';
import styles from './Button.module.css';
import ClockLoader from 'react-spinners/ClockLoader';
import clx from 'classnames';

type Props = {
  btnRef?: RefObject<HTMLButtonElement>;
  buttonType?: 'button' | 'submit' | 'reset' | undefined;
  children?: ReactNode;
  className?: any;
  dataTestId?: any;
  disabled?: boolean;
  fullWidth?: boolean;
  height?: any;
  Icon?: any;
  TrailingIcon?: FC<{ className?: string }>;
  loading?: any;
  margin?: any;
  name?: string;
  /** @deprecated use variant prop instead of type */
  type?: '' | 'primary' | 'clear' | 'clear_outline' | 'clear_selected';
  variant?:
  | 'primary'
  | 'outlined'
  | 'tertiary'
  | 'hideTextOnMobile'
  | 'outlinedHideTextOnMobile';
  size?: 'small' | 'regular';
  width?: any;
  handleClick?: (e?: any) => void;
  style?: Record<string, any>;
  isExpanded?: boolean
};

const Button: FC<Props> = ({
  btnRef,
  buttonType,
  children = null,
  className,
  dataTestId,
  disabled,
  fullWidth = false,
  height,
  Icon,
  TrailingIcon,
  loading,
  name,
  type = '',
  size,
  variant = 'primary',
  width,
  handleClick,
  style,
  isExpanded
}) => {
  return (
    <button
      ref={btnRef}
      style={{
        ...(width && {
          width: typeof width === 'number' ? `${width}px` : width,
        }),
        ...(height && {
          height: typeof height === 'number' ? `${height}px` : height,
        }),
        ...style,
      }}
      data-test-id={dataTestId}
      type={buttonType}
      name={name}
      tabIndex={-1}
      disabled={disabled || loading}
      onClick={handleClick}
      className={clx(styles.btn, className, {
        [styles.primary]: variant === 'primary',
        [styles.outlined]: variant === 'outlined',
        [styles.tertiary]: variant === 'tertiary',
        [styles.loading]: loading,
        [styles.fullWidth]: fullWidth,
        [styles.onlyIcon]: !children,
        [styles.onlyText]: !Icon,
        /* Deprecated */
        [styles.clear]: type === 'clear',
        [styles.clear_selected]: type === 'clear_selected',
        [styles.clear_outline]: type === 'clear_outline',
        /* size */
        [styles.small]: size === 'small',
        [styles.hideTextOnMobile]: variant === 'hideTextOnMobile',
        [styles.outlinedHideTextOnMobile]:
          variant === 'outlinedHideTextOnMobile',
      })}
    >
      {loading && (
        <ClockLoader
          className={styles.loader}
          color={`var(--btn-${variant}-color)`}
          size={16}
          cssOverride={{
            position: !Icon ? 'absolute' : 'relative',
          }}
        />
      )}
      {Icon && !loading && <Icon className={styles.icon_wrapper} />}
      {children && <span>{children}</span>}
      {TrailingIcon && <TrailingIcon /* className={styles.icon_trailing}  */

        className={`${styles.icon_trailing} ${isExpanded ? styles.expanded_icon : ''}`}
      />}
    </button>
  );
};

export default Button;
