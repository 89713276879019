import { memo, useContext } from 'react';
import { LogoFullIcon } from './LogoFullIcon';
import { ThemeContext } from 'common/context/Theme';
import { themeTypes } from 'common/context/Theme/ThemeContext';

import { renderTxtColor } from 'common/utils/theme';

const Logo = () => {
  const { theme, secondaryColor } = useContext<themeTypes>(ThemeContext);
  return (
    <>
      <style jsx>{`
        .logo_wrapper {
          display: flex;
          justify-content: center;
          fill: ${renderTxtColor(theme)};
        }
      `}</style>
      <div className="logo_wrapper">
        <LogoFullIcon colour={secondaryColor} />
      </div>
    </>
  );
};

export default memo(Logo);
