export const highlightSearchPhrase = (
  searchPhrase: string,
  text: string,
  highlightTag: 'strong' | 'b' = 'b',
): string => {
  const regex = new RegExp(`(${searchPhrase})`, 'gi');
  const highlightedText = text.replace(regex, (match) => {
    const isExactMatch = match.toLowerCase() === searchPhrase.toLowerCase();
    if (isExactMatch) {
      const originalCaseMatch = getOriginalCaseMatch(match, searchPhrase);
      return `<${highlightTag}>${originalCaseMatch}</${highlightTag}>`;
    } else {
      return match;
    }
  });
  return highlightedText;
};

export const getOriginalCaseMatch = (
  match: string,
  searchPhrase: string,
): string => {
  const index = match.toLowerCase().indexOf(searchPhrase.toLowerCase());
  const originalCaseMatch = match.substr(index, searchPhrase.length);
  return originalCaseMatch;
};
