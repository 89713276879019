import clx from 'classnames';

interface IconProps {
  className?: string;
  width?: string | number;
  height?: string | number;
  colour?: string;
}

export const LogoFullIcon = ({
  colour = '#211F1F',
  className,
  width = '119',
  height = '30',
}: IconProps) => {
  return (
    <svg
      className={clx(className)}
      width={width}
      height={height}
      viewBox="0 0 119 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.1274 29.3538C35.5205 29.3538 35.0432 29.1798 34.7033 28.8235C34.3635 28.4756 34.1855 27.9702 34.1855 27.3239V2.4855C34.1855 1.83927 34.3554 1.33389 34.7033 0.985916C35.0512 0.637945 35.5367 0.455675 36.1678 0.455675H50.3999C50.9258 0.455675 51.3223 0.588235 51.5893 0.84507C51.8563 1.10191 51.9857 1.47473 51.9857 1.96355C51.9857 2.47722 51.8563 2.86661 51.5893 3.14002C51.3223 3.41342 50.9258 3.54598 50.3999 3.54598H37.875V13.1234H49.607C50.1086 13.1234 50.4889 13.256 50.7559 13.5294C51.0229 13.8028 51.1524 14.1674 51.1524 14.623C51.1524 15.1367 51.0229 15.5344 50.7559 15.7995C50.4889 16.0646 50.1086 16.2055 49.607 16.2055H37.875V27.3239C37.875 28.6744 37.2925 29.3538 36.1274 29.3538Z"
        fill={colour}
      />
      <path
        d="M76.4368 29.4366C74.5354 29.4366 72.8848 29.0141 71.4851 28.1773C70.0853 27.3405 69.0011 26.1475 68.2325 24.6065C67.4638 23.0655 67.0836 21.251 67.0836 19.1715C67.0836 17.6056 67.302 16.1889 67.7389 14.9296C68.1758 13.6703 68.7989 12.5932 69.6241 11.7067C70.4413 10.8119 71.4284 10.1326 72.5774 9.66032C73.7263 9.18807 75.0128 8.94781 76.4449 8.94781C78.3463 8.94781 79.9968 9.37034 81.3966 10.2071C82.7963 11.0439 83.8805 12.2287 84.6491 13.7614C85.4178 15.2941 85.7981 17.1002 85.7981 19.1798C85.7981 20.7457 85.5796 22.1707 85.1427 23.4383C84.7058 24.7142 84.0747 25.7912 83.2575 26.686C82.4403 27.5808 81.4532 28.2602 80.3043 28.7324C79.1554 29.2046 77.8689 29.4449 76.4368 29.4449V29.4366ZM76.4368 26.512C77.6019 26.512 78.6133 26.2303 79.4709 25.6587C80.3286 25.087 80.992 24.2668 81.4532 23.1814C81.9144 22.0961 82.149 20.7622 82.149 19.1632C82.149 16.7854 81.6312 14.971 80.6036 13.7283C79.5761 12.4855 78.1844 11.8641 76.4449 11.8641C75.3121 11.8641 74.3088 12.1458 73.4512 12.6926C72.5935 13.2477 71.922 14.0679 71.4527 15.145C70.9753 16.2303 70.7407 17.5642 70.7407 19.1632C70.7407 21.541 71.2585 23.3637 72.3104 24.623C73.3541 25.8824 74.7377 26.512 76.453 26.512H76.4368Z"
        fill={colour}
      />
      <path
        d="M58.2805 29.4946C57.2853 29.4946 56.4843 28.6661 56.4843 27.6553V1.83927C56.4843 0.820215 57.2934 0 58.2805 0C59.2676 0 60.0768 0.828501 60.0768 1.83927V27.6471C60.0768 28.6661 59.2676 29.4863 58.2805 29.4863V29.4946Z"
        fill={colour}
      />
      <path
        d="M3.61526 19.5167L0.337008 22.8735C-0.111675 23.333 -0.111675 24.0779 0.337008 24.5373L3.61526 27.8942C4.06394 28.3536 4.7914 28.3536 5.24009 27.8942L8.51834 24.5373C8.96702 24.0779 8.96702 23.333 8.51834 22.8735L5.24009 19.5167C4.7914 19.0572 4.06394 19.0572 3.61526 19.5167Z"
        fill="#3EAFEC"
      />
      <path
        d="M3.61501 10.1544L0.33676 13.5112C-0.111922 13.9707 -0.111923 14.7156 0.33676 15.175L3.61501 18.5319C4.0637 18.9913 4.79116 18.9913 5.23984 18.5319L8.51809 15.175C8.96677 14.7156 8.96677 13.9707 8.51809 13.5112L5.23984 10.1544C4.79116 9.69494 4.0637 9.69494 3.61501 10.1544Z"
        fill="#3EAFEC"
      />
      <path
        d="M3.61477 0.792066L0.336512 4.14892C-0.112171 4.60836 -0.112171 5.35326 0.336512 5.8127L3.61477 9.16955C4.06345 9.62899 4.79091 9.62899 5.23959 9.16955L8.51784 5.8127C8.96653 5.35326 8.96653 4.60836 8.51784 4.14892L5.23959 0.792067C4.79091 0.332626 4.06345 0.332626 3.61477 0.792066Z"
        fill="#3EAFEC"
      />
      <path
        d="M12.7579 0.791813L9.4796 4.14867C9.03092 4.60811 9.03092 5.35301 9.4796 5.81245L12.7579 9.1693C13.2065 9.62874 13.934 9.62874 14.3827 9.1693L17.6609 5.81245C18.1096 5.35301 18.1096 4.60811 17.6609 4.14867L14.3827 0.791813C13.934 0.332372 13.2065 0.332372 12.7579 0.791813Z"
        fill="#3EAFEC"
      />
      <path
        d="M21.901 0.791558L18.6227 4.14841C18.174 4.60785 18.174 5.35275 18.6227 5.81219L21.901 9.16905C22.3496 9.62849 23.0771 9.62849 23.5258 9.16905L26.804 5.81219C27.2527 5.35275 27.2527 4.60785 26.804 4.14841L23.5258 0.79156C23.0771 0.332119 22.3496 0.332118 21.901 0.791558Z"
        fill="#3EAFEC"
      />
      <path
        d="M12.7581 10.1541L9.47985 13.511C9.03117 13.9704 9.03117 14.7153 9.47985 15.1748L12.7581 18.5316C13.2068 18.9911 13.9342 18.9911 14.3829 18.5316L17.6612 15.1748C18.1099 14.7153 18.1099 13.9704 17.6612 13.511L14.3829 10.1541C13.9342 9.69468 13.2068 9.69468 12.7581 10.1541Z"
        fill="#FF9140"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.483 29.6189C95.8552 29.8674 96.2921 30 96.7938 30L96.7695 29.9917C97.3035 29.9917 97.7485 29.8592 98.1207 29.5857C98.4929 29.3123 98.7842 28.8981 98.9945 28.3264L103.755 14.9739L108.51 28.3264C108.72 28.8981 109.019 29.3123 109.4 29.5857C109.78 29.8592 110.225 29.9917 110.726 29.9917C111.228 29.9917 111.673 29.8592 112.053 29.5857C112.434 29.3123 112.717 28.8981 112.903 28.3264L118.809 12.2121C118.971 11.7813 119.028 11.367 118.987 10.9776C118.947 10.5882 118.809 10.2651 118.575 10.0249C118.34 9.78459 117.984 9.66032 117.507 9.66032C117.086 9.66032 116.73 9.76802 116.439 9.96686C116.147 10.174 115.913 10.5468 115.727 11.0853L110.714 25.4428L105.702 11.0853C105.573 10.5965 105.346 10.2403 105.03 10.0083C104.715 9.77631 104.318 9.66032 103.841 9.66032C103.388 9.66032 103.008 9.77631 102.708 10.0083C102.401 10.2403 102.158 10.5965 101.972 11.0853L96.9048 25.3885L91.9877 11.1682C91.8016 10.5965 91.5832 10.2071 91.3162 9.99172C91.0572 9.77631 90.7012 9.6686 90.2481 9.6686C89.7708 9.6686 89.3905 9.77631 89.0992 9.99172C88.8079 10.2071 88.6218 10.5054 88.5409 10.8865C88.4681 11.2676 88.5167 11.715 88.7028 12.2287L94.6092 28.343C94.8196 28.9395 95.1108 29.3621 95.483 29.6189ZM102.846 12.4192L103.755 14.9739L104.666 12.4192H102.846Z"
        fill={colour}
      />
      <path
        d="M104.666 12.4192H102.846L103.755 14.9739L104.666 12.4192Z"
        fill={colour}
      />
    </svg>
  );
};
