import { useEffect } from 'react';

interface ArrowKeysProps {
  onLeftArrowPress?: () => void;
  onRightArrowPress?: () => void;
}

export const useArrowKeys = ({
  onLeftArrowPress,
  onRightArrowPress,
}: ArrowKeysProps) => {
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'ArrowLeft':
          if (onLeftArrowPress) {
            onLeftArrowPress();
          }
          break;
        case 'ArrowRight':
          if (onRightArrowPress) {
            onRightArrowPress();
          }
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onLeftArrowPress, onRightArrowPress]);
};
