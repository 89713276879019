interface BsLogoProps {
  className?: string;
  height?: number;
  width?: number;
}

export const BsLogo = ({
  className,
  height = 28,
  width = 115,
}: BsLogoProps) => {
  return (
    <>
     <style jsx>{`
      .cls-1 {
        fill: #fd6f2f;
      }
     `}</style>
      <svg
        className={className}
        height={height}
        width={width}
        viewBox="0 0 1920 510.04"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        data-testid="bs-logo"
      >
       <path className="cls-1" d="M579.69,257.73c5.9-5.33,10.24-11.24,13-17.72,2.76-6.47,4.14-13.33,4.14-20.58,0-18.67-6-33.34-18-44.01-12-10.67-28.48-16-49.44-16h-89.74v212.91h89.74c23.81,0,42.44-5.76,55.87-17.29,13.43-11.53,20.15-27.48,20.15-47.87,0-9.72-1.91-18.86-5.72-27.44-3.81-8.57-10.48-15.91-20-22ZM478.52,192.57h52.87c8,0,14.38,2.19,19.15,6.58,4.76,4.38,7.14,10.29,7.14,17.72s-2.38,13.48-7.14,18.15c-4.76,4.67-11.15,7-19.15,7h-52.87v-49.44ZM557.54,329.6c-6,5.62-14.05,8.43-24.15,8.43h-54.87v-62.02h54.87c10.1,0,18.15,2.81,24.15,8.43,6,5.62,9,13.2,9,22.72s-3,16.81-9,22.43Z"/>
       <path className="cls-1" d="M680.28,220.29c-7.24,3.43-12.86,9.62-16.86,18.58v-22.01h-35.15v155.47h37.44v-86.59c0-11.81,3.29-21.01,9.86-27.58,6.57-6.57,15.1-9.86,25.58-9.86h13.43v-33.15h-9.14c-9.53,0-17.91,1.71-25.15,5.14Z"/>
       <path className="cls-1" d="M926.05,234.87c-11.82-14.29-27.72-21.43-47.73-21.43-14.29,0-27.06,3.28-38.29,9.86-11.24,6.58-20.1,15.58-26.58,27.01-6.48,11.43-9.72,24.48-9.72,39.15s3.24,27.53,9.72,39.15c6.47,11.62,15.43,20.77,26.86,27.43,11.43,6.67,24.39,10,38.86,10,18.86,0,33.82-6.1,44.87-18.29v16.86c0,11.43-3.62,20.34-10.86,26.72-7.24,6.38-16.48,9.57-27.72,9.57-9.91,0-18.25-2.24-25.01-6.71-6.76-4.48-11.29-10.34-13.57-17.58l-34.87,11.56c4.95,13.72,14.05,26.31,27.29,34.6,13.24,8.29,28.62,12.43,46.15,12.43,14.86,0,28.01-3.05,39.44-9.15,11.43-6.1,20.38-14.48,26.86-25.15,6.47-10.67,9.72-22.77,9.72-36.29v-147.75h-35.44v18.01ZM912.76,319.74c-7.53,7.81-17.2,11.72-29.01,11.72s-21.86-3.95-29.58-11.86c-7.72-7.9-11.57-17.86-11.57-29.86,0-8,1.76-15.19,5.29-21.58,3.52-6.38,8.43-11.38,14.72-15,6.29-3.62,13.33-5.43,21.15-5.43,11.81,0,21.48,3.95,29.01,11.86,7.52,7.91,11.29,17.96,11.29,30.15s-3.76,22.2-11.29,30.01Z"/>
       <path className="cls-1" d="M1099.95,220.86c-8.86-4.95-19.01-7.43-30.44-7.43-8.96,0-17.05,1.57-24.29,4.71-7.24,3.15-13.05,7.86-17.43,14.15v-76.3h-37.44v216.34h37.44v-91.17c0-10.09,2.95-18.19,8.86-24.29,5.9-6.1,13.43-9.14,22.58-9.14s16.67,3,22.58,9c5.9,6,8.86,14.15,8.86,24.43v91.17h37.44v-100.02c0-11.62-2.48-21.86-7.43-30.72-4.96-8.86-11.86-15.76-20.72-20.72Z"/>
       <path className="cls-1" d="M1213.83,334.89c-4.19-4.19-6.29-10.19-6.29-18.01v-66.59h35.72v-33.44h-35.72v-35.44h-37.44v12c0,7.62-2.1,13.43-6.29,17.43-4.19,4-10.1,6-17.72,6h-2.86v33.44h26.86v68.59c0,17.34,4.81,30.86,14.43,40.58,9.62,9.72,23.19,14.57,40.72,14.57,6.29,0,12.48-.48,18.58-1.43v-32.29c-3.62.57-6.96.86-10,.86-9.14,0-15.82-2.09-20-6.28Z"/>
       <path className="cls-1" d="M1374.72,254.58l-39.15-12.29c-7.62-2.47-13.39-5.95-17.29-10.43-3.91-4.47-5.86-9.67-5.86-15.57,0-7.81,3-14.15,9-19.01,6-4.86,13.95-7.29,23.86-7.29,11.05,0,20.76,2.91,29.15,8.72,8.38,5.81,14.67,14.05,18.86,24.72l32.01-10.71c-5.72-16.01-15.67-32.44-29.86-42.16-14.2-9.72-30.91-14.58-50.15-14.58-13.91,0-26.25,2.62-37.01,7.86-10.77,5.24-19.15,12.53-25.15,21.86-6,9.34-9,20.1-9,32.29,0,13.91,4.19,25.96,12.57,36.15,8.38,10.19,20.86,17.96,37.44,23.29l40.87,13.15c7.43,2.48,13,5.72,16.72,9.71,3.71,4,5.57,9.15,5.57,15.43,0,7.81-3.1,14.1-9.29,18.86-6.19,4.76-14.34,7.14-24.43,7.14-12.19,0-23.15-3.47-32.86-10.43-9.72-6.95-17.15-16.53-22.29-28.72l-32.29,10.63c3.81,11.82,9.86,25.52,18.15,34.38,8.29,8.86,18.19,15.77,29.72,20.72,11.52,4.95,24.05,7.43,37.58,7.43,14.67,0,27.53-2.67,38.58-8,11.05-5.33,19.72-12.67,26.01-22.01,6.29-9.33,9.43-20.1,9.43-32.29,0-28.39-16.96-48.01-50.87-58.87Z"/>
       <path className="cls-1" d="M1585.91,334.89c-4.19-4.19-6.29-10.19-6.29-18.01v-66.59h35.72v-33.44h-35.72v-35.44h-37.44v12c0,7.62-2.1,13.43-6.29,17.43-4.19,4-10.1,6-17.72,6h-2.86v33.44h26.86v68.59c0,17.34,4.81,30.86,14.43,40.58,9.62,9.72,23.19,14.57,40.72,14.57,6.29,0,12.48-.48,18.58-1.43v-32.29c-3.62.57-6.96.86-10,.86-9.14,0-15.82-2.09-20-6.28Z"/>
       <path className="cls-1" d="M1878.84,283.16l-24.86-7.43c-5.34-1.52-9.72-3.52-13.15-6-3.43-2.47-5.14-5.9-5.14-10.29s1.81-8.29,5.43-11.15c3.62-2.86,8.57-4.29,14.86-4.29,7.81,0,14.91,2.1,21.29,6.29,6.38,4.19,10.81,9.82,13.29,16.86l29.44-9.66c-4.76-12.58-13.63-26.2-24.87-33.35-11.24-7.14-24.2-10.72-38.87-10.72-11.24,0-21.2,2-29.86,6-8.67,4-15.43,9.57-20.29,16.72-4.86,7.14-7.29,15.48-7.29,25.01,0,10.67,3.38,19.91,10.15,27.72,6.76,7.81,16.72,13.62,29.86,17.43l25.43,7.14c4.95,1.33,9.19,3.28,12.72,5.86,3.52,2.57,5.29,6.05,5.29,10.43,0,5.15-2.05,9.2-6.14,12.15-4.1,2.96-9.48,4.43-16.15,4.43-8.76,0-16.67-2.48-23.72-7.43-4.32-3.04-11.8-10.48-15.05-14.95l-27.42,8.91c4.87,10.58,12.51,24.14,22.89,30.9,12.29,8,26.72,12,43.3,12,11.81,0,22.1-2,30.87-6,8.76-4,15.62-9.57,20.58-16.72,4.95-7.14,7.43-15.38,7.43-24.72,0-10.86-3.43-20.19-10.29-28.01-6.86-7.81-16.77-13.52-29.72-17.15Z"/>
      <rect className="cls-1" x="740.87" y="216.86" width="37.44" height="155.47"/>
      <circle className="cls-1" cx="759.59" cy="176.57" r="22.86" transform="translate(-18.47 123.95) rotate(-9.22)"/>
      <rect className="cls-1" x="1451.59" y="216.86" width="37.44" height="155.47"/>
      <circle className="cls-1" cx="1470.31" cy="176.57" r="22.86"/>
      <path className="cls-1" d="M1749.38,223.86c-11.24-6.95-24.96-10.43-41.15-10.43-14.48,0-27.58,3.53-39.29,10.57-11.72,7.05-20.96,16.62-27.72,28.72-6.77,12.1-10.15,25.96-10.15,41.58s3.33,28.77,10,41.15c6.67,12.39,16,22.2,28.01,29.44,12,7.24,26.01,10.86,42.01,10.86s29.67-3.57,41.58-10.72c11.9-7.14,20.24-21.14,25.01-32.19l-30.29-9.96c-3.43,5.91-8.15,10.72-14.15,14.43s-13.29,5.57-21.86,5.57c-11.24,0-20.67-3.43-28.29-10.29-7.62-6.86-12-16-13.15-27.43h112.03c.76-2.09,1.29-4.52,1.57-7.29.29-2.76.43-5.57.43-8.43,0-13.9-2.96-26.58-8.86-38.01-5.91-11.43-14.48-20.62-25.72-27.58ZM1670.79,276.59c2.09-10.29,6.53-18.24,13.29-23.86,6.76-5.62,14.81-8.43,24.15-8.43,9.9,0,18.15,3,24.72,9,6.57,6,10.24,13.77,11,23.29h-73.16Z"/>
      <path className="cls-1" d="M242.76,400.91c-1.75-2.63-5.29-3.35-7.92-1.6l-121.69,78.82c-2.63,1.75-3.35,5.29-1.6,7.92l14.22,21.43c1.75,2.63,5.29,3.35,7.92,1.6l121.69-78.82c2.63-1.75,3.35-5.29,1.6-7.92l-14.22-21.43Z"/>
      <path className="cls-1" d="M167.3,0S167.22,0,167.16,0C167.12,0,167.08,0,167.04,0,74.77.08,0,73.3,0,163.61c0,27.18,6.26,55.23,21.75,77.75,20.9,30.38,48.33,60.66,51.24,97.75.35,4.51,5.21,7.17,9.17,4.98l14.24-9.25c1.93-1.07,3.07-3.14,2.95-5.35-.79-14.43-6.29-55.14-42.07-99.85-14.6-18.24-19.24-42.62-19.24-66.03,0-69.68,57.93-126.37,129.13-126.38,71.2,0,129.13,56.7,129.13,126.38,0,23.42-4.64,47.79-19.24,66.03-13.5,16.87-24.95,35.52-32.99,55.6-3.3,8.25-4.09,16.63-9.93,23.61-6.03,7.21-14.35,11.94-22.13,16.98-12.32,7.98-24.64,15.96-36.96,23.94-20.63,13.37-68.2,42.69-88.84,56.05-2.63,1.75-3.35,5.29-1.6,7.92l14.22,21.43c1.75,2.63,5.29,3.35,7.92,1.6,22.07-14.3,71.08-44.55,93.15-58.84,19.13-12.39,40.84-22.57,55.73-40.37,8.2-9.79,9.89-21.18,15.15-32.52,5.75-12.4,13.33-23.86,21.21-34.99,6.8-9.61,13.92-19,20.6-28.71,15.49-22.52,21.75-50.57,21.75-77.75C334.34,73.3,259.57.08,167.3,0Z"/>
      </svg>
    </>
  )
}
