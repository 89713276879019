import { FC, useState } from 'react';
import dynamic from 'next/dynamic';
import FieldSet from '../components/FieldSet';
import Label from '../components/Label';

export const defaultVal = [
  {
    type: 'p',
    children: [{ text: '' }],
  },
];

const NewSlateEditor = dynamic(
  () => import('components/FlowUi/NewSlateEditor/SlateEditor'),
  {
    ssr: false,
  },
);
interface Props {
  value: any;
  onChange: any;
  attributes?: any;
  placeholder?: any;
  dataTestId?: any;
  isLocked?: any;
  label?: any;
  variant?: any;
}

const MarkupField: FC<Props> = ({
  value,
  onChange,
  isLocked,
  label,
  variant = 'simple',
}) => {
  // @ts-ignore

  const [focused, setFocused] = useState(false);
  const [open, setOpen] = useState(false);
  const onFocus = () => {
    setFocused(true);
  };

  const onBlur = () => {
    setFocused(false);
  };

  const isEmpty =
    JSON.stringify(value) === '[{"type":"p","children":[{"text":""}]}]';
  //value ? value?.every((d: any) => getIsEmpty(d)) : true
  return (
    <>
      <style jsx>{`
        .wrapper {
          display: inline-flex;
          flex-direction: column;
          position: relative;
          min-width: 0px;
          padding: 0px;
          margin: 0px;
          border: 0px;
          vertical-align: top;
          width: 100%;
          margin: 8px 0;
        }
        .select_inner_wrapper {
          display: -webkit-inline-box;
          display: -webkit-inline-flex;
          display: -ms-inline-flexbox;
          display: inline-flex;
          -webkit-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column;
          position: relative;
          min-width: 0;
          padding: 0;
          margin: 0;
          border: 0;
          vertical-align: top;
          width: 100%;
          z-index: 5;
          min-width: 250px;
          min-height: 50px;
          font-size: 15px;
        }
        @media (max-width: 620px) {
          .select_inner_wrapper {
            font-size: 18px;
          }
        }
      `}</style>

      <div className="wrapper">
        {variant === 'simple' && (
          <Label
            focused={focused || !isEmpty}
            open={false}
            value={false}
            label={label}
          />
        )}
        <div className="select_inner_wrapper">
          <div
            style={{
              padding: '0 5px ',
              width: '100%',
              borderRadius: 4,
              fontFamily: 'Figtree',
              fontStyle: 'normal',
              fontWeight: 400,
              zIndex: 1000000000,
              userSelect: 'none',
              background: isLocked ? '#e8e8e8e0' : 'transparent',
            }}
            contentEditable={false}
          >
            <NewSlateEditor
              content={value || defaultVal}
              onChange={onChange}
              variant={variant}
              isLocked={isLocked}
              onFocus={onFocus}
            />
          </div>
          {variant === 'simple' && (
            <FieldSet
              focused={focused || !isEmpty}
              open={false}
              value={false}
              label={label}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default MarkupField;
