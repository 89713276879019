import { ThemeContext } from 'common/context/Theme';
import { themeTypes } from 'common/context/Theme/ThemeContext';
import { useContext } from 'react';

const Label = ({
  open = false,
  value,
  selected = false,
  label,
  focused = false,
  warning,
  alignRight,
}: any) => {
  const { secondaryColor } = useContext<themeTypes>(ThemeContext);

  const renderClassName = () => {
    if (warning) {
      return 'label_focused_error';
    }
    if (open || value || selected || focused) {
      return 'select_label';
    } else {
      return 'label';
    }
  };
  return (
    <>
      <style jsx>{`
        .select_label {
          color: ${secondaryColor};
          font-family: Nunito;
          line-height: 1.4375em;
          letter-spacing: 0.00938em;
          font-weight: 400;
          font-size: 16.6px;
          line-height: 1.4375em;
          letter-spacing: 0.00938em;
          padding: 0;
          position: relative;
          display: block;
          transform-origin: top left;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: calc(133% - 24px);
          position: absolute;
          left: 0;
          top: 0;
          -webkit-transform: translate(14px, -9px) scale(0.75);
          -moz-transform: translate(14px, -9px) scale(0.75);
          -ms-transform: translate(14px, -9px) scale(0.75);
          transform: translate(14px, -9px) scale(0.75);
          -webkit-transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
            -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
            max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
          transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
            transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
            max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
          z-index: 2;
          pointer-events: auto;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }
        .label {
          color: ${secondaryColor};
          font-family: Nunito;
          line-height: 1.4375em;
          letter-spacing: 0.00938em;
          font-weight: 400;
          font-size: 14px;
          line-height: 1.4375em;
          letter-spacing: 0.00938em;
          padding: 0;
          position: relative;
          display: block;
          transform-origin: top left;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: calc(100% - 24px);
          position: absolute;
          left: 0;
          top: 0;
          -webkit-transform: ${`translate(${
            alignRight ? 38 : 14
          }px, 14px) scale(1)`};
          -moz-transform: ${`translate(${
            alignRight ? 38 : 14
          }px, 14px) scale(1)`};
          -ms-transform: ${`translate(${
            alignRight ? 38 : 14
          }px, 14px) scale(1)`};
          transform: ${`translate(${alignRight ? 38 : 14}px, 14px) scale(1)`};
          -webkit-transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
            -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
            max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
          transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
            transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
            max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

          pointer-events: none;
          border-radius: 8px;
          z-index: 2;
        }
        .label_focused_error {
          color: rgba(203, 0, 0, 0.6);
          font-family: Nunito;
          line-height: 1.4375em;
          letter-spacing: 0.00938em;
          font-weight: 400;
          font-size: 1rem;
          line-height: 1.4375em;
          letter-spacing: 0.00938em;
          padding: 0;
          position: relative;
          display: block;
          transform-origin: top left;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: calc(133% - 24px);
          position: absolute;
          left: 0;
          top: 0;
          -webkit-transform: translate(14px, -9px) scale(0.75);
          -moz-transform: translate(14px, -9px) scale(0.75);
          -ms-transform: translate(14px, -9px) scale(0.75);
          transform: translate(14px, -9px) scale(0.75);
          -webkit-transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
            -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
            max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
          transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
            transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
            max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
          z-index: 1;
          pointer-events: auto;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }
        @media (max-width: 620px) {
          .label {
            font-size: 18px;
            -webkit-transform: translate(13px, 12px) scale(1);
            -moz-transform: translate(13px, 12px) scale(1);
            -ms-transform: translate(13px, 12px) scale(1);
            transform: translate(13px, 12px) scale(1);
          }
        }
      `}</style>
      <label className={renderClassName()}>
        {' '}
        {typeof label === 'object' ? Object.values(label) : label}
      </label>
    </>
  );
};

export default Label;
