import moment from 'moment';

export * from './date';

export const formatTimeAgo = (date: any) => {
  if (moment.unix(date).isSame(new Date(), 'day')) {
    return moment.unix(date).format('h:mm a');
  }
  return moment.unix(date).fromNow();
};

export const formatTime = (date: any) => {
  return moment.unix(date).format('h:mm a');
};

export const timeAgo = (date: any) => {
  return moment.unix(date).fromNow();
};

export const renderShortDate = (date: any) => {
  let dd = date.getDate();
  let mm = date.getMonth() + 1;

  let yyyy = date.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  return dd + '/' + mm + '/' + yyyy;
};

export function getTimeOfDay(timestamp: number): string | null {
  try {
    const date = new Date(timestamp);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      throw new Error('Invalid timestamp provided.');
    }

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
  } catch (error) {
    console.error(error);
    return null;
  }
}
export const renderDateKey = (date: any) => {
  let dd = date.getDate();
  let mm = date.getMonth() + 1;
  var hours = date.getHours();
  var seconds = date.getSeconds(); // Get the seconds from the date object

  let yyyy = date.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  if (seconds < 10) {
    // Ensure seconds are formatted as two digits
    seconds = '0' + seconds;
  }

  return yyyy + '/' + mm + '/' + dd + '/' + hours + '/' + seconds + '/';
};
export const addYears = (dt: any, n: any) => {
  return new Date(dt.setFullYear(dt.getFullYear() + n));
};

export const minusYears = (dt: any, n: any) => {
  return new Date(dt.setFullYear(dt.getFullYear() - n));
};

function formatAMPM(date: Date): string {
  const hours24 = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  // const ampm = hours24 >= 12 ? 'pm' : 'am';

  // let hours12 = hours24 % 12;
  // hours12 = hours12 ? hours12 : 12; // the hour '0' should be '12'

  const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

  return `${hours24}:${formattedMinutes}:${formattedSeconds}`;
}

export const renderShortDateWithTime = (date: any) => {
  let dd = date.getDate();
  let mm = date.getMonth() + 1;

  let yy = date.getFullYear().toString().slice(-2);
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  return dd + '/' + mm + '/' + yy + ' ' + formatAMPM(date);
};
