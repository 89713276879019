"use client";

import React, {
  createContext,
  ReactNode,
  FC,
  useState,
  useEffect,
} from 'react';
import Cookies from 'universal-cookie';
import clx from 'classnames';

type Props = {
  children: ReactNode;
  app_theme: any;
};
const isServer = typeof window === 'undefined';
export interface themeTypes {
  theme: string;
  setTheme: any;
  primaryColor: any;
  secondaryColor: any;
  setPrimaryColor: any;
  themeBackground: any;
  secondaryThemeBackground: any;
  boxShadow: any;
  boxShadowLight: any;
  activeColor: any;
  disabledColor: any;
  warningColor: any;
  errorColor: any;
  successColor: any;
  infoColor: any;
  textColor: any;
  colours: any
}
export const colours: any = {
  primary500: '#0093E5',
  primary400: '#00A3FFe6',
  primary300: '#66C8FF',
  primary200: '#B2E3FF',
  primary100: '#E6F6FF',
  neutral800: '#19191A',
  neutral700: '#4C4C4D',
  neutral600: '#7E7F80',
  neutral500: '#979899',
  neutral400: '#CACBCC',
  neutral300: '#E3E5E5',
  neutral200: '#F0F1F2',
  neutral100: '#FCFEFF',
};

export const themeColors: any = {
  default: '#ffffff',
  defaultHighContrast: '#ffffff',
  defaultDark: '#535353',
  darkHighContrast: '#000000',
};

const themeBoxShadow: any = {
  default: 'rgba(33, 150, 243, 0.4)',
  defaultHighContrast: 'rgba(33, 150, 243, 0.4)',
  defaultDark: '#d4751665',
  darkHighContrast: '#d4751680',
};

const themeBoxShadowLight: any = {
  default: '#d1d1d1',
  defaultHighContrast: '#d1d1d1',
  defaultDark: '#3a3737',
  darkHighContrast: '#1d1c1c',
};

const themeBackgroundolors: any = {
  default: `${colours.neutral100}`,
  defaultHighContrast: '#f6f6f6',
  defaultDark: '#535353',
  darkHighContrast: `${colours.neutral800}`,
};
const secondarythemeBackgroundolors: any = {
  default: '#ffffff',
  defaultHighContrast: '#ffffff',
  defaultDark: '#535353',
  darkHighContrast: '#000000',
};

const secondary: any = {
  default: 'rgba(0, 0, 0, 0.566)',
  defaultHighContrast: 'rgb(0, 0, 0)',
  defaultDark: '#e0e0e0',
  darkHighContrast: 'white',
};

const primary: any = {
  default: '#00a3ff',
  defaultHighContrast: '#00a3ff',
  defaultDark: '#66C8FF',
  darkHighContrast: '#66C8FF',
};

const text: any = {
  default: '#4C4C4D',
  defaultHighContrast: '#4C4C4D',
  defaultDark: '#FCFEFF',
  darkHighContrast: '#FCFEFF',
};

const active: any = {
  default: '#0093E5',
  defaultHighContrast: '#0093E5',
  defaultDark: '#00A3FF',
  darkHighContrast: '#00A3FF',
};

const disabled: any = {
  default: '#979899',
  defaultHighContrast: '#979899',
  defaultDark: '#979899',
  darkHighContrast: '#979899',
};

const success: any = {
  default: '#EBFAF7',
  defaultHighContrast: '#EBFAF7',
  defaultDark: '#208070',
  darkHighContrast: '#208070',
};

const error: any = {
  default: '#FEECED',
  defaultHighContrast: '#FEECED',
  defaultDark: '#A62A36',
  darkHighContrast: '#A62A36',
};

const warning: any = {
  default: '#FFF4EC',
  defaultHighContrast: '#FFF4EC',
  defaultDark: '#CC7433',
  darkHighContrast: '#CC7433',
};

const info: any = {
  default: '#EEF5FF',
  defaultHighContrast: '#EEF5FF',
  defaultDark: '#2958A6',
  darkHighContrast: '#2958A6',
};

export const device: any = {
  mobileS: '(min-width: 320px)',
  maxMobileS: '(max-width: 319px)',
  tablet: '(min-width: 768px)',
  maxTablet: '(max-width: 767px)',
  laptop: '(min-width: 1000px)',
  maxLaptop: '(max-width: 999px)',
  desktop: '(min-width: 2560px)',
};

const findThemeKey = (selectedValue: string): string | null => {
  const foundedEntry = Object.entries(themeColors).find(
    (c) => c[1] === selectedValue,
  );
  return foundedEntry ? foundedEntry?.[0] : null;
};

export const Context = createContext({} as any);

const cookies = new Cookies();

export const Provider: FC<Props> = ({ children, app_theme }) => {
  const [theme, setThemeState] = useState(
    themeColors[app_theme] ? themeColors[app_theme] : themeColors.default,
  );

  const themeKey = findThemeKey(theme);

  useEffect(() => {
    if (!isServer && document) {
      document.body.classList.remove('theme-default');
      document.body.classList.remove('theme-defaultHighContrast');
      document.body.classList.remove('theme-defaultDark');
      document.body.classList.remove('theme-darkHighContrast');
      document.body.classList.add(`theme-${themeKey}`);
    }
  }, [themeKey]);

  const [themeBackground, setThemeBackground] = useState(
    themeBackgroundolors[app_theme]
      ? themeBackgroundolors[app_theme]
      : themeBackgroundolors.default,
  );

  const [boxShadow, setThemeBoxShadow] = useState(
    themeBoxShadow[app_theme]
      ? themeBoxShadow[app_theme]
      : themeBoxShadow.default,
  );

  const [boxShadowLight, setThemeBoxShadowLight] = useState(
    themeBoxShadowLight[app_theme]
      ? themeBoxShadowLight[app_theme]
      : themeBoxShadowLight.default,
  );

  const [secondaryThemeBackground, setSecondaryThemeBackground] = useState(
    secondarythemeBackgroundolors[app_theme]
      ? secondarythemeBackgroundolors[app_theme]
      : secondarythemeBackgroundolors.default,
  );
  const [primaryColor, setPrimaryColor] = useState(
    primary[app_theme] ? primary[app_theme] : primary.default,
  );
  const [activeColor, setActiveColor] = useState(
    active[app_theme] ? active[app_theme] : active.default,
  );
  const [disabledColor, setDisabledColor] = useState(
    disabled[app_theme] ? disabled[app_theme] : disabled.default,
  );
  const [secondaryColor, setSecondaryColor] = useState(
    secondary[app_theme] ? secondary[app_theme] : secondary.default,
  );
  const [warningColor, setWarningColor] = useState(
    warning[app_theme] ? warning[app_theme] : warning.default,
  );
  const [errorColor, setErrorColor] = useState(
    error[app_theme] ? error[app_theme] : error.default,
  );
  const [successColor, setSuccessColor] = useState(
    success[app_theme] ? success[app_theme] : success.default,
  );
  const [infoColor, setInfoColor] = useState(
    info[app_theme] ? info[app_theme] : info.default,
  );
  const [textColor, setTextColor] = useState(
    text[app_theme] ? text[app_theme] : text.default,
  );
  const setTheme = (theme: string) => {
    setThemeState(themeColors[theme]);
    setPrimaryColor(primary[theme]);
    setSecondaryColor(secondary[theme]);
    setThemeBoxShadow(themeBoxShadow[theme]);
    setThemeBoxShadowLight(themeBoxShadowLight[theme]);
    setThemeBackground(themeBackgroundolors[theme]);
    setSecondaryThemeBackground(secondarythemeBackgroundolors[theme]);
    setActiveColor(active[theme]);
    setDisabledColor(disabled[theme]);
    setWarningColor(warning[theme]);
    setErrorColor(error[theme]);
    setSuccessColor(success[theme]);
    setInfoColor(info[theme]);
    setTextColor(text[theme]);
    // Save to storage
    try {
      cookies.remove('flow_theme');

      setTimeout(() => {
        cookies.set('flow_theme', theme, {
          path: '/',
          expires: new Date(
            new Date().setFullYear(new Date().getFullYear() + 1),
          ),
          sameSite: true,
        });
      }, 100);
    } catch (e) {
      console.log(e);
      // Unsupported
    }
  };

  const configContext = {
    theme,
    setTheme,
    primaryColor,
    secondaryColor,
    setPrimaryColor,
    themeBackground,
    secondaryThemeBackground,
    boxShadow,
    boxShadowLight,
    activeColor,
    disabledColor,
    warningColor,
    errorColor,
    successColor,
    infoColor,
    textColor,
    colours
  };

  return (
    <Context.Provider value={configContext}>
      <div className={clx(`theme-${themeKey}`)}>{children}</div>
    </Context.Provider>
  );
};

export const { Consumer } = Context;
